import React, { FC, FocusEvent, ReactNode } from "react";
import { _throw } from "@airmont/shared/ts/utils/core";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { PropertyValueType } from "./PropertyValueType";
import { InputBaseProps } from "@mui/material/InputBase";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";
import { RemoveButton } from "./RemoveButton";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { usePropertyEditBase } from "./usePropertyEditBase";

export interface PropertyEditStringProps extends PropertyEditBaseProps {
  multiline?: boolean;
  type?: PropertyValueType;
  onBlur?: (event: FocusEvent) => void;
  unit?: string;
  autoFocus?: boolean;
  helperText?: ReactNode;
  error?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  inputRef?: React.Ref<HTMLInputElement>;
}

export const PropertyEditString: FC<PropertyEditStringProps> = (props) => {
  const { removable, handleRemove } = usePropertyEditBase(props);
  const inputType = resolveHtmlInputType(props.type ?? "string");

  const sx = useSxMerge({
    width: props.fullWidth !== true ? "25ch" : undefined,
    ".MuiInputBase-root": {
      pr: 1,
    },
  });
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const type =
      props.type != null
        ? props.type
        : resolvePropertyValueTypeFromValue(props.value);
    const typedValue = typeValue(event.target.value, type);

    props.onChange(
      typedValue,
      props.name ?? _throw(new Error("name cannot be null"))
    );
  };

  const handleBlur = (event: FocusEvent) => {
    props.onBlur?.(event);
  };


  return (
    <TextField
      className={"Property"}
      autoFocus={props.autoFocus}
      label={props.label}
      name={props.name ?? _throw(new Error("name cannot be null"))}
      value={props.value ?? ""}
      size={props.size}
      type={inputType}
      multiline={props.multiline}
      onChange={handleTextFieldChange}
      onBlur={handleBlur}
      fullWidth={props.fullWidth ?? false}
      inputProps={props.inputProps}
      inputRef={props.inputRef}
      InputProps={{
        endAdornment:
          props.unit || props.info || removable ? (
            <InputAdornment position="end">
              {props.unit ? props.unit : undefined}
              {props.info && (
                <Tooltip title={props.info}>
                  <IconButton size={"small"}>
                    <HelpOutlineRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {removable && <RemoveButton onRemove={handleRemove} />}
            </InputAdornment>
          ) : undefined,
      }}
      helperText={props.helperText}
      error={props.error}
      sx={sx}
    />
  );
};

const resolveHtmlInputType = (
  type: PropertyValueType
): React.InputHTMLAttributes<unknown>["type"] | undefined => {
  if (type === "number") {
    return "number";
  } else if (type === "password") {
    return "password";
  } else {
    return "text";
  }
};

const typeValue = (
  valueAsString: string,
  type: PropertyValueType
): string | number => {
  if (type === "number") {
    return +valueAsString;
  } else {
    return valueAsString;
  }
};
