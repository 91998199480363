import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { DurationSeries } from "@airmont/firefly/shared/ts/timeseries";
import { XAxisDefaultConfig } from "@airmont/shared/ts/ui/mui";
import { Duration } from "luxon";
import {
  DurationFormatter,
  RelativeTimeFormatPartUtils,
} from "@airmont/shared/ts/ui/time";

const scaleType = "point";

type ReturnType = XAxisDefaultConfig[];
export const resolveXAxisConfig = (
  timeSeries: Array<DurationSeries<number | null>> | undefined,
  axisConfig?: XAxisDefaultConfig
): ReturnType => {
  if (timeSeries === undefined || timeSeries?.length === 0) {
    return [
      {
        id: "time",
        scaleType: scaleType,
        data: [],
      },
    ] as ReturnType;
  }

  const sortedTimeSeries = [...timeSeries];
  sortedTimeSeries.sort((a, b) => {
    if (a.firstPoint === undefined && b.firstPoint === undefined) {
      return 0;
    }
    if (a.firstPoint === undefined) {
      return -1;
    }
    if (b.firstPoint === undefined) {
      return 1;
    }
    if (a.firstPoint?.duration < b.firstPoint?.duration) {
      return -1;
    }
    if (a.firstPoint?.duration > b.firstPoint?.duration) {
      return 1;
    }
    return 0;
  });

  const axisData: Array<number> = [];
  sortedTimeSeries.forEach((ts, tsIndex) => {
    if (tsIndex === 0) {
      timeSeries[tsIndex].points.forEach((point) => {
        axisData.push(point.duration.toMillis());
      });
    } else {
      timeSeries[tsIndex].points.forEach((point) => {
        const existingTimeIndex = axisData.findIndex(
          (it) => it === point.duration.toMillis()
        );
        if (existingTimeIndex === -1) {
          axisData.push(point.duration.toMillis());
        }
      });
    }
  });

  const durationFormatter = new DurationFormatter({
    locales: "no",
    options: {
      timeDirection: "none",
      style: "short",
    },
  });

  const valueFormatterNew = (
    durationInMillis: number,
    context: AxisValueFormatterContext
  ): string => {
    const duration = Duration.fromMillis(durationInMillis).shiftToAll();
    const parts = durationFormatter.formatToParts(duration);
    return RelativeTimeFormatPartUtils.toString(parts);
  };

  const longestDurationInMillis = axisData[axisData.length - 1];
  const displayTickAtEvery =
    longestDurationInMillis <= 3600000
      ? 300000
      : longestDurationInMillis < 18000000
      ? 1800000
      : 3600000;

  if (axisConfig != null) {
    return [
      {
        ...axisConfig,
        id: "time",
        scaleType: scaleType,
        data: axisData,
        valueFormatter: valueFormatterNew,
        tickInterval: (value: number, index: number) =>
          value % displayTickAtEvery === 0,
      } as XAxisDefaultConfig,
    ];
  } else {
    return [
      {
        id: "time",
        scaleType: scaleType,
        data: axisData,
        valueFormatter: valueFormatterNew,
        tickInterval: (value: number, index: number) =>
          value % displayTickAtEvery === 0,
      } as XAxisDefaultConfig,
    ];
  }
};
