export const localizationText = {
  "components" : {
    "MuiCharts": {
      "defaultProps": {
        "localeText": {
          "noData": "Ingen data"
        }
      }
    }
  }
}
