import { User } from "shared-ts-utils-authentication";
import { UnsupportedError } from "@airmont/shared/ts/utils/core";

export type IdentityUserId = string;

export interface IdentityUserDto {
  id: IdentityUserId;
  userName: string;
  email: string;
  emailConfirmed: boolean;
}

export class IdentityUser implements User {
  id: string;
  name: string;
  email: string;
  emailConfirmed: boolean;
  get accessToken(): string {
    throw new UnsupportedError("accessToken not supported by IdentityUser");
  }

  constructor(args: IdentityUserDto) {
    this.id = args.id;
    this.email = args.email;
    this.emailConfirmed = args.emailConfirmed;
    this.name = args.userName;
    this.name = args.userName;
  }

  hasClaim(claim: string): boolean {
    return false;
  }

  hasRole(role: string): boolean {
    return false;
  }

  getRole(): string {
    throw new Error("Method not implemented.");
  }

  isSessionActive(): boolean {
    return false;
  }
}
