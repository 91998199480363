import { SettingConfig } from "../SettingConfig";
import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { SettingKey } from "../SettingKeyDto";
import { AsyncSettingsStorage } from "./AsyncSettingsStorage";
import { AppKey } from "shared-ts-app-info";
import { UserSettingDto } from "./UserSetting";
import { SettingOverrides } from "../SettingOverrides";

export interface ServerStorageOptions {
  baseApiPath?: string;
}
export class ServerSettingsStorage implements AsyncSettingsStorage {
  private readonly fetchHelper;
  public readonly type: "Async";

  public constructor(options?: ServerStorageOptions) {
    this.fetchHelper = new FetchHelper({
      basePath: options?.baseApiPath ?? "/api/user-settings/",
    });
    this.type = "Async";
  }

  loadSettings(
    appKey: AppKey,
    user: string | null | undefined
  ): Promise<Array<UserSettingDto>> {
    return this.fetchHelper
      .get(`load?app=${appKey}&user=${user}`)
      .fetchAsJson();
  }

  async readValue<Type>(
    key: SettingKey,
    config: SettingConfig<Type>,
    options?: SettingOverrides<Type>
  ): Promise<Type | null> {
    const value = await this.fetchHelper
      .get(`get?app=${key.app}&user=${key.user}&name=${key.name}`)
      .fetchAsJson<string | null>();
    return Promise.resolve(
      value != null
        ? options?.deserialize != null
          ? options.deserialize(value)
          : config.deserialize(value)
        : null
    );
  }

  writeValue<Type>(
    key: SettingKey,
    newValue: Type | null,
    config: SettingConfig<Type>,
    overrides?: SettingOverrides<Type>
  ): Promise<void> {
    return this.fetchHelper
      .post("set", {
        app: key.app,
        user: key.user,
        name: key.name,
        value:
          newValue != null
            ? overrides?.serialize != null
              ? overrides.serialize(newValue)
              : config.serialize(newValue)
            : null,
        type: config.type,
      })
      .fetch();
  }
}
