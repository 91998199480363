import { FC } from "react";
import { BurnAssessment } from "./BurnAssessment";
import { resolveBurnAssessmentIcon } from "./resolveBurnAssessmentIcon";
import { Stack, SxProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InfoPill } from "@airmont/shared/ts/ui/info-pill";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { Button_color } from "@airmont/shared/ts/ui/mui";
import { BurnAssessmentColor } from "./BurnAssessmentColor";

export interface BurnAssessmentInfoProps {
  value: BurnAssessment;
  color?: Button_color;
  variant?: "normal" | "outlined" | "filled";
  layout: SizeClass;
  sx?: SxProps;
}

export const BurnAssessmentInfo: FC<BurnAssessmentInfoProps> = (props) => {
  const { value, layout } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");
  const UnknownAssessmentIcon = resolveBurnAssessmentIcon(
    BurnAssessment.Unknown
  );
  const GoodAssessmentIcon = resolveBurnAssessmentIcon(BurnAssessment.Good);
  const NormalAssessmentIcon = resolveBurnAssessmentIcon(BurnAssessment.Normal);
  const BadAssessmentIcon = resolveBurnAssessmentIcon(BurnAssessment.Bad);

  return (
    <InfoPill
      color={props.color}
      variant={props.variant}
      label={t("Ignition Quality")}
      layout={layout}
      info={
        <Stack
          direction={"row"}
          useFlexGap
          gap={0.5}
          sx={{ alignItems: "center", justifySelf: "center" }}
        >
          {value === BurnAssessment.Bad && (
            <Tooltip title={t("BurnAssessment.Bad")}>
              <BadAssessmentIcon
                fontSize={"small"}
                sx={{
                  color: BurnAssessmentColor[BurnAssessment.Bad],
                }}
              />
            </Tooltip>
          )}
          {value === BurnAssessment.Normal && (
            <Tooltip title={t("BurnAssessment.Normal")}>
              <NormalAssessmentIcon
                fontSize={"small"}
                sx={{
                  color: BurnAssessmentColor[BurnAssessment.Normal],
                }}
              />
            </Tooltip>
          )}
          {value === BurnAssessment.Good && (
            <Tooltip title={t("BurnAssessment.Good")}>
              <GoodAssessmentIcon
                fontSize={"small"}
                sx={{
                  color: BurnAssessmentColor[BurnAssessment.Good],
                }}
              />
            </Tooltip>
          )}
          {value === BurnAssessment.Unknown && (
            <Tooltip title={t("BurnAssessment.Unknown")}>
              <UnknownAssessmentIcon
                fontSize={"small"}
                sx={{
                  color: BurnAssessmentColor[BurnAssessment.Unknown],
                }}
              />
            </Tooltip>
          )}
        </Stack>
      }
    />
  );
};
