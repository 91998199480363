import { FC, ReactNode, useState } from "react";
import { Box } from "@mui/material";
import { TextField_size, useSxMerge } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { useResolveBorderColor } from "./useResolveBorderColor";

export interface PropertyWrapperProps {
  label: string;
  name?: string;
  size?: TextField_size;
  fullWidth?: boolean;
  multiline?: boolean;
  sx?: SxProps;
  children?: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const PropertyWrapper: FC<PropertyWrapperProps> = (props) => {
  const [hover, setHover] = useState(false);
  const size = props.size ?? "medium";
  const borderColor = useResolveBorderColor(hover);

  const handleMouseEnter = () => {
    setHover(true);
    props.onMouseEnter?.();
  };

  const handleMouseLeave = () => {
    setHover(false);
    props.onMouseLeave?.();
  };

  const sx = useSxMerge(
    { width: props.fullWidth === true ? "100%" : "195px" },
    props.sx,
    {
      display: "inline-flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      padding: 0,
      margin: 0,
      border: 0,
      verticalAlign: "top",
    }
  );

  return (
    <Box
      title={props.label}
      className={"Property PropertyWrapper"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={sx}
    >
      <Box
        component={"label"}
        className={"Label"}
        sx={{
          display: "block",
          transformOrigin: "top left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(133% - 32px)",
          position: "absolute",
          left: 0,
          top: 0,
          transform: "translate(14px, -9px) scale(0.75);",
          transition:
            "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          zIndex: 1,
          pointerEvents: "auto",
          userSelect: "none",
        }}
      >
        {props.label}
      </Box>
      <Box
        className={"Container"}
        sx={{
          borderRadius: "4px",
          display: "inline-flex",
          alignItems: "center",
          width: "inherit",
          minHeight: size === "small" ? "40px" : "56px",
          height:
            props.multiline === true
              ? undefined
              : size === "small"
              ? "40px"
              : "56px",
        }}
      >
        {props.children}
        <Box
          component={"fieldset"}
          sx={{
            textAlign: "left",
            position: "absolute",
            bottom: 0,
            right: 0,
            top: "-5px",
            left: 0,
            margin: 0,
            padding: "0 8px",
            pointerEvents: "none",
            borderRadius: "inherit",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: borderColor,
            minWidth: "0%",
          }}
        >
          <Box
            title={props.label}
            component={"legend"}
            sx={{
              float: "unset",
              width: "auto",
              overflow: "hidden",
              display: "block",
              padding: 0,
              height: "11px",
              fontSize: "0.75em",
              visibility: "hidden",
              maxWidth: "100%",
              transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;",
              whiteSpace: "nowrap",
            }}
          >
            {props.label}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
