import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  enUS as muiCore_enUS,
  Localization as MuiCoreLocalization,
  nbNO as muiCore_nbNO,
} from "@mui/material/locale";
import { localizationText as charts_en } from "./chartsLocaleText.en";
import { localizationText as charts_no } from "./chartsLocaleText.no";
import {
  enUS as dataGrid_enUS,
  nbNO as dataGrid_nbNO,
} from "@mui/x-data-grid-premium/locales";
import { LocalizationProvider as DatePickerLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { nbNO as dp_nbNO } from "@mui/x-date-pickers/locales";
import { GridLocaleText } from "@mui/x-data-grid/models/api/gridLocaleTextApi";
import { PickersLocaleText } from "@mui/x-date-pickers";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import {merge} from "lodash";

export interface MuiDataGridLocalization {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: Partial<GridLocaleText>;
      };
    };
  };
}

export interface MuiDataPickersLocalization {
  components: {
    MuiLocalizationProvider: {
      defaultProps: {
        localeText: Partial<PickersLocaleText<never>>;
      };
    };
  };
}

export interface ChartsLocaleText {
  noData: string;
}

export interface MuiChartsLocalization {
  components: {
    MuiCharts: {
      defaultProps :{
        localeText: Partial<ChartsLocaleText>;
      }
    }
  }
}

export type MuiLocalization = MuiCoreLocalization & MuiDataGridLocalization & MuiChartsLocalization;

const mui_enUS: MuiLocalization = merge(muiCore_enUS, dataGrid_enUS , charts_en) as MuiLocalization;
const mui_nbNo: MuiLocalization = merge(muiCore_nbNO, dataGrid_nbNO , charts_no) as MuiLocalization;

const localizationByLanguage: Record<string, MuiLocalization> = {
  en: mui_enUS,
  no: mui_nbNo,
  "en-US": mui_enUS,
  "en-GB": mui_enUS,
  "nb-NO": mui_nbNo,
  "nn-NO": mui_nbNo,
};

interface MuiLocalizationContextType {
  muiLocalization: MuiLocalization;
  setLocale: (language: string) => void;
}

const MuiLocalizationContext = createContext<MuiLocalizationContextType>({
  muiLocalization: mui_enUS,
  setLocale: () => {
    /* Nothing to do */
  },
});

export interface MuiLocalizationProviderProps {
  language: string;
  children: ReactNode;
}

export const MuiLocalizationProvider: FC<MuiLocalizationProviderProps> = (
  props
) => {
  const [value, setValue] = useState(resolveLocalization(props.language));
  const setLocale = useCallback((language: string) => {
    setValue(resolveLocalization(language));
  }, []);

  useEffect(() => {
    setValue(resolveLocalization(props.language));
  }, [props.language]);

  const contextValue: MuiLocalizationContextType = useMemo(() => {
    return {
      muiLocalization: value,
      setLocale: setLocale,
    };
  }, [value, setLocale]);
  return (
    <MuiLocalizationContext.Provider value={contextValue}>
      <DatePickerLocalizationProvider
        localeText={
          dp_nbNO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        {props.children}
      </DatePickerLocalizationProvider>
    </MuiLocalizationContext.Provider>
  );
};

export const useMuiLocalization = (): MuiLocalizationContextType => {
  return useContext(MuiLocalizationContext);
};

const resolveLocalization = (language: string): MuiLocalization => {
  const localization = localizationByLanguage[language];
  if (localization == null) {
    throw new IllegalArgumentError("Language not supported: " + language);
  }
  return localization;
};
