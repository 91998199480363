import { List as MuiList, SxProps, useTheme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import React, { useMemo } from "react";
import {
  ItemType,
  ItemVariant,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import { renderListItem } from "./renderListItem";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface ListProps extends CommonProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  items: Array<ItemType>;
  hideLabels?: boolean;
  onSelected?: (item: SelectableItem) => void;
  sx?: SxProps;
}

export const List = (props: ListProps) => {
  const { layout, layoutDirection, items } = props;
  const variant = props.variant ?? "rounded";
  const theme = useTheme();
  const hideLabels = props.hideLabels ?? false;

  const handleSelectedItem = (item: SelectableItem) => {
    props.onSelected?.(item);
  };

  const sx = useSxMerge(props.sx, {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    "&.layout-large": {
      ".GroupMenu": {
        backgroundColor: "unset",
      },
    },
    "&.layout-medium": {
      ml: "2px",
      mr: "2px",
    },
  });

  const className = useMemo(() => {
    let classNameBuilder =
      "Item GroupList-list variant-" + variant + " layout-" + layout;

    if (layoutDirection != null) {
      classNameBuilder += " direction-" + layoutDirection;
    }
    return classNameBuilder;
  }, [layout, variant, layoutDirection]);

  return (
    <MuiList disablePadding className={className} sx={sx}>
      {items
        .filter((it) => it != null)
        .map((item, index) => {
          return renderListItem({
            variant: variant,
            layout: layout,
            index: index,
            item: item,
            onSelected: handleSelectedItem,
            hideLabels: hideLabels,
          });
        })}
    </MuiList>
  );
};
