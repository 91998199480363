import { McUser } from "firefly/my-chimney/ts/user";
import { FC } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import {
  PropertiesCard,
} from "@airmont/shared/ts/ui/properties-card";
import { useTranslation } from "react-i18next";
import {Property} from "shared-ts-property";

export interface UserCardProps {
  user: McUser;
}
export const UserCard: FC<UserCardProps> = (props) => {
  const { user } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  return (
    <Card variant={"outlined"}>
      <CardHeader title={user.fullName}></CardHeader>
      <CardContent>
        <PropertiesCard
          elevation={0}
          childPropertyProps={{
            fullWidth: true,
          }}
        >
          <Property
            name={"username"}
            label={t("Username")}
            value={user.name}
            mode={"read"}
          />
        </PropertiesCard>
        <Typography>
          {t("You have access to the following addresses")}
          {": "}
        </Typography>
        <Stack direction={"column"} useFlexGap gap={1}>
          {user.addresses.map((address) => {
            return (
              <Card variant={"outlined"}>
                <CardContent>
                  <Typography>
                    {t("Municipality")}
                    {": "}
                    {address.municipalityId}
                  </Typography>
                  <Typography>{address.streetAddress.asString}</Typography>
                  <Typography>{address.postalCode}</Typography>
                  {address.cadastralNumbers?.cadastralUnit != null && (
                    <Typography>
                      {address.cadastralNumbers.cadastralUnit} /{" "}
                      {address.cadastralNumbers.propertyUnit} /{" "}
                      {address.cadastralNumbers.leaseNumber}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
