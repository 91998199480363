import { DateTime, Interval } from "luxon";
import {
  _throw,
  IllegalArgumentError,
  notUndef,
  RandomUtils,
} from "@airmont/shared/ts/utils/core";
import { BurnDto } from "./Burn";
import { BurnAssessment } from "./assessment/BurnAssessment";
import { FlueId } from "../chimney/flue/FlueId";
import { BurnNonSensitiveDto } from "./BurnNonSensitive";
import { FlueTypeStringsEnum } from "../chimney/flue/FlueType";

let burnCount = 1;

export const createBurnNonSensitiveDto = (args?: {
  flueId?: FlueId;
  temperatureSeriesId?: string;
  metrics?: {
    chimneyFire?: boolean;
    temperatureMax?: number;
  };
}): BurnNonSensitiveDto => {
  const burnDto = createBurnDto(args);
  return createBurnNonSensitiveDtoFromBurnDto(burnDto);
};

export const createBurnNonSensitiveDtoFromBurnDto = (
  burnDto: BurnDto
): BurnNonSensitiveDto => {
  const endTime =
    burnDto.endTime != null
      ? DateTime.fromISO(burnDto.endTime)
      : DateTime.local();
  const startTime = DateTime.fromISO(burnDto.startTime);
  const duration = endTime.toMillis() - startTime.toMillis();
  return {
    id: burnDto.id,
    flueId: burnDto.flueId,
    temperatureSeriesId: burnDto.temperatureSeriesId,
    year: startTime.year,
    month: startTime.month,
    durationInMillis: duration,
    completed: true,
    metrics: burnDto.metrics,
    weather: burnDto.weather,
    analytics: burnDto.analytics,
  };
};

export const createBurnDto = (args?: {
  flueId?: FlueId;
  temperatureSeriesId?: string;
  metrics?: {
    chimneyFire?: boolean;
    temperatureMax?: number;
  };
}): BurnDto => {
  const burnId = burnCount++;
  const burnStartTime = DateTime.local(2023, 1, burnId, 8);
  const burnEndTime = burnStartTime.plus({
    hours: RandomUtils.getRandomInt(2, 8),
  });
  const startTime =
    burnStartTime.toISO() ?? _throw(new IllegalArgumentError("startTime"));
  const endTime =
    args?.metrics?.chimneyFire === true
      ? undefined
      : burnEndTime.toISO() ?? _throw(new IllegalArgumentError("burnEndTime"));
  const interval = notUndef(burnEndTime, (it) =>
    Interval.fromDateTimes(burnStartTime, it)
  );
  return {
    id: `${burnId}`,
    flueId: args?.flueId ?? "1",
    temperatureSeriesId: args?.temperatureSeriesId ?? "ts-1",
    anonymized: false,
    completed: true,
    startTime: startTime,
    endTime: endTime,
    year: burnStartTime.year,
    month: burnStartTime.month,
    durationInMillis:
      interval?.toDuration().toMillis() ??
      _throw(new IllegalArgumentError("durationInMillis")),
    metrics: {
      burnHourCount: 5,
      temperatureMax: 240,
      sootIndex: 5,
      deltaTemperature: 330,
      deltaTemperatureMinute: 66,
      chimneyFire: args?.metrics?.chimneyFire ?? false,
    },
    weather: {
      outsideTempC: -5,
      atmosphericPressureHpa: 90,
      relativeHumidityPct: 5,
      windSpeedMs: 3,
      windDirectionDeg: 90,
    },
    analytics: {
      startQuality: BurnAssessment.Good,
      predictedFlueType: FlueTypeStringsEnum.Brick,
      predictedFlueTypeConfidence: 100,
    },
  };
};
