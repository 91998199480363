import React, { FC } from "react";
import { Box, IconButton, Link, Tooltip } from "@mui/material";
import { PropertyWrapper } from "./PropertyWrapper";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { ValueUtils } from "./ValueUtils";
import { isLink } from "./Link";
import { IllegalArgumentError, notUndef } from "@airmont/shared/ts/utils/core";
import { PropertyReadProps } from "./PropertyRead";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
export const PropertyReadLink: FC<PropertyReadProps> = (props) => {
  if (typeof props.value === "number") {
    throw new IllegalArgumentError("value cannot be a number");
  } else if (typeof props.value === "boolean") {
    throw new IllegalArgumentError("value cannot be a boolean");
  } else if (props.value instanceof File) {
    throw new IllegalArgumentError("value cannot be a File");
  }
  const { t } = useTranslation();
  const displayValue = ValueUtils.resolveDisplayValue({
    value: props.value,
    type: props.type ?? resolvePropertyValueTypeFromValue(props.value),
    t: t,
    valueOptions: props.valueOptions,
  });

  let href: string | undefined = undefined;
  if (props.value == null) {
    href = undefined;
  } else if (typeof props.value === "string") {
    href = props.value;
  } else if (isLink(props.value)) {
    href = props.value.href ?? undefined;
  }

  const isReactRouterLink = notUndef(href, (it) => it.startsWith("/")) ?? false;

  const sx = useSxMerge(props.sx, {
    ".Container": {
      ml: "14px",
    },
  });

  return (
    <PropertyWrapper
      label={props.label}
      name={props.name}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={sx}
    >
      {isReactRouterLink && href !== undefined && (
        <Link noWrap={!props.multiline} component={RouterLink} to={href}>
          {displayValue}
        </Link>
      )}
      {!isReactRouterLink && (
        <Link href={href} noWrap={!props.multiline}>
          {displayValue}
        </Link>
      )}
      <Box sx={{ marginLeft: "auto" }}>
        {props.info != null && (
          <Tooltip title={props.info}>
            <IconButton size={"small"}>
              <HelpOutlineRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </PropertyWrapper>
  );
};
