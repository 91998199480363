import { Property } from "shared-ts-property";
import React, { FC } from "react";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalStateError,
  notNullOrUndefined,
} from "@airmont/shared/ts/utils/core";

export interface EstimatedSweepDatePropertyProps {
  value?: DateTimeISO | null;
}
export const EstimatedSweepDateProperty: FC<EstimatedSweepDatePropertyProps> = (
  props
) => {
  const dateTime = notNullOrUndefined(
    props.value,
    (it) => DateTime.fromISO(it) ?? _throw(new IllegalStateError("value"))
  );

  return (
    <Property
      name={"flue.estimatedSweepDate"}
      label={"Estimert feiedato"}
      type={
        dateTime == null
          ? undefined
          : dateTime.year === 10000
          ? "string"
          : "date"
      }
      value={dateTime == null ? null : dateTime.year === 10000 ? "∞" : dateTime}
    />
  );
};
