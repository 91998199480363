import React, { FC, useRef } from "react";
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  useTheme,
} from "@mui/material";
import { LinkItem } from "./LinkItem";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { ItemVariant } from "./ItemVariant";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import { useClassName } from "./useResolveClassName";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { useResolveItemName } from "./useResolveItemName";

export interface LinkListItemProps {
  item: LinkItem;
  variant?: ItemVariant;
  layout?: Layout;
  layoutDirection?: LayoutDirection;
  hideLabel?: boolean;
  sx?: SxProps;
}

export const LinkListItem: FC<LinkListItemProps> = (props) => {
  const { item } = props;
  const ref = useRef<HTMLLIElement>(null);
  const name = useResolveItemName(item, ref.current);
  const hideLabel = props.hideLabel ?? false;
  const displayLabel = !hideLabel;
  const classNameRoot = useClassName(props, ["Item", "LinkItem"]);
  const theme = useTheme();
  const sx = useSxMerge(props.sx, {
    justifyContent: hideLabel ? "center" : undefined,
    "&.direction-column": {
      "&.layout-medium": {
        borderRadius: 3,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  });
  return (
    <ListItem ref={ref} className={classNameRoot} sx={sx}>
      <Link
        target={item.target ?? "_blank"}
        href={item.href}
        underline={"none"}
        color={"text.primary"}
        sx={{
          borderRadius: "inherit",
          ">.MuiListItemText-root": {
            ">.MuiTypography-root": {
              fontSize: "inherit",
              textAlign: "inherit",
            },
          },
          flexGrow: 1,
          minWidth: 0,
          display: "flex",
          alignItems: "center",
          "&.layout-medium": {
            fontSize: "0.75rem",
          },
          "&.layout-compact": {
            fontSize: "0.75rem",
          },
          ".MuiListItemIcon-root:first-child": {
            minWidth: hideLabel ? "unset" : "36px",
          },
        }}
      >
        {item.icon != null && <ListItemIcon>{item.icon}</ListItemIcon>}
        {displayLabel && <ListItemText primary={name} sx={{ mt: 0, mb: 0 }} />}
        {displayLabel && (
          <ListItemIcon sx={{ marginLeft: "auto", ml: 1, minWidth: "unset" }}>
            <OpenInNewRoundedIcon fontSize="small" />
          </ListItemIcon>
        )}
      </Link>
    </ListItem>
  );
};
