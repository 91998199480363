import { ReactNode } from "react";
import { Item, ItemType } from "./Item";
import { isSelectableItem } from "./SelectableItem";
import { Layout } from "./Layout";

export interface ActionGroupPreferenceProps {
  displayInline?: boolean;
  displayGroupNode?: boolean;
  hideGroupNodeSelected?: boolean;
  hideSelected?: boolean;
  hideLabels?: boolean;
}

export interface ActionGroupPreferences extends ActionGroupPreferenceProps {
  compact?: ActionGroupPreferenceProps;
  medium?: ActionGroupPreferenceProps;
  large?: ActionGroupPreferenceProps;
}

export interface ActionGroup extends Item {
  type: "ActionGroup";
  name?: string;
  nameBreakable?: string;
  reference?: string;
  items: ItemType[];
  icon?: ReactNode;
  disabled?: boolean;
  selectable?: boolean;
  selected?: boolean;
  preferences?: ActionGroupPreferences;
}

export function isActionGroup(obj: object): obj is ActionGroup {
  return typeof obj === "object" && "type" in obj && obj.type === "ActionGroup";
}

export function hasItemsSelected(actionGroup: ActionGroup): boolean {
  return actionGroup.items.reduce((a, item) => {
    if (a) {
      return true;
    }
    if (isSelectableItem(item) && item.selected) {
      return true;
    }

    if (isActionGroup(item)) {
      return hasItemsSelected(item);
    }

    return false;
  }, false);
}

export const resolveActionGroupPreferences = (
  actionGroupPreferences: ActionGroupPreferences | undefined,
  layout: Layout
): ActionGroupPreferenceProps | undefined => {
  if (actionGroupPreferences === undefined) {
    return undefined;
  }
  const preferenceByLayout = actionGroupPreferences[layout];
  if (preferenceByLayout != null) {
    return preferenceByLayout;
  }
  return actionGroupPreferences;
};
