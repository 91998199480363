import React, { FC, useMemo } from "react";
import { PropertyWrapper } from "./PropertyWrapper";
import { Slider, Stack, Typography } from "@mui/material";
import { isRangeDto, RangeDto } from "@airmont/shared/ts/utils/core";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import { usePropertyEditBase } from "./usePropertyEditBase";
import { RemoveButton } from "./RemoveButton";

export interface SliderComponentProps {
  min?: number;
  max?: number;
  step?: number | null;
}

export interface PropertyEditSliderProps extends PropertyEditBaseProps {
  value: RangeDto | number;
  unit?: string;
  componentProps?: SliderComponentProps;
}

export const PropertyEditSlider: FC<PropertyEditSliderProps> = (props) => {
  const { removable, handleRemove } = usePropertyEditBase(props);
  const { name, value, unit } = props;
  const valueAsArrayOrNumber = useMemo(() => {
    if (isRangeDto(value)) {
      return [value.min, value.max];
    } else {
      return value;
    }
  }, [value]);
  const getAriaValueText = (value: number): string => {
    return unit != null ? value + " " + unit : value.toString();
  };

  const handleChange = (event: Event, value: number | number[]) => {
    if (Array.isArray(value)) {
      props.onChange({ min: value[0], max: value[1] }, name);
    } else {
      props.onChange(value, name);
    }
  };

  return (
    <PropertyWrapper
      label={props.label}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={props.sx}
    >
      <Stack
        direction={"row"}
        gap={2}
        useFlexGap
        sx={{
          ml: "14px",
          mr: "7px",
          flexWrap: "nowrap",
          flexGrow: 1,
          minWidth: 0,
          alignItems: "center",
        }}
      >
        <Slider
          value={valueAsArrayOrNumber}
          onChange={handleChange}
          onBlur={props.onBlur}
          size="small"
          getAriaValueText={getAriaValueText}
          {...props.componentProps}
        />
        <Stack direction={"row"} alignItems={"center"} flexWrap={"nowrap"}>
          {isRangeDto(value) && (
            <Typography variant={"caption"} lineHeight={1.2} noWrap>
              {value.min} - {value.max}
            </Typography>
          )}
          {typeof value === "number" && (
            <Typography variant={"caption"}>{value}</Typography>
          )}
          {unit != null && <Typography variant={"caption"}>{unit}</Typography>}
          {removable && <RemoveButton onRemove={handleRemove} />}
        </Stack>
      </Stack>
    </PropertyWrapper>
  );
};
