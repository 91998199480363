import { MuiThemeOption } from "./editor/MuiThemeOption";

export const overridableThemeOptions: Array<MuiThemeOption> = [
  {
    name: "Primary.main",
    path: "palette.primary.main",
  },
  {
    name: "Secondary.main",
    path: "palette.secondary.main",
  },
  {
    name: "Background.default",
    path: "palette.background.default",
  },
  {
    name: "Background.paper",
    path: "palette.background.paper",
  },
];
