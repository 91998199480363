import {Obj} from "@popperjs/core";

export interface RangeDto {
  min: number;
  max: number;
}


export function isRangeDto(obj: any): obj is RangeDto {
  return obj != null
    && typeof obj === "object"
    && obj !== null
    && "min" in obj
    && "max" in obj
    && typeof obj.min === "number"
    && typeof obj.max === "number";
}
