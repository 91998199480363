import React, { FC } from "react";
import { Box, IconButton, Switch, Tooltip } from "@mui/material";
import { PropertyWrapper } from "./PropertyWrapper";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { _throw } from "@airmont/shared/ts/utils/core";
import { RemoveButton } from "./RemoveButton";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import { usePropertyEditBase } from "./usePropertyEditBase";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface PropertyEditBooleanProps extends PropertyEditBaseProps {
  value: boolean | undefined;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const PropertyEditBoolean: FC<PropertyEditBooleanProps> = (props) => {
  const { removable, handleRemove } = usePropertyEditBase(props);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    props.onChange(
      value,
      props.name ?? _throw(new Error("name cannot be null"))
    );
  };

  const sx = useSxMerge(props.sx, {
    ".Container": {
      paddingRight: "7px",
      ".MuiSwitch-root": {
        marginLeft: "14px",
      },
    },
  });

  return (
    <PropertyWrapper
      label={props.label}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={sx}
    >
      <Switch
        name={props.name}
        checked={props.value}
        onChange={handleChange}
        onBlur={props.onBlur}
        inputRef={props.inputRef}
        size={props.size}
      />
      {(props.info || removable) && (
        <Box sx={{ marginLeft: "auto" }}>
          {props.info && (
            <Tooltip title={props.info}>
              <IconButton size={"small"}>
                <HelpOutlineRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
          {removable && <RemoveButton onRemove={handleRemove} />}
        </Box>
      )}
    </PropertyWrapper>
  );
};
