import React, { FC, ReactNode } from "react";
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { PropertyValue } from "./PropertyValue";
import { PropertyWrapper } from "./PropertyWrapper";
import { PropertyValueType } from "./PropertyValueType";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { SxProps } from "@mui/system";
import { ValueOptions } from "./ValueOptions";
import { ValueUtils } from "./ValueUtils";
import { PropertyReadLink } from "./PropertyReadLink";
import { isValueOption } from "./ValueOption";
import { useTranslation } from "react-i18next";
import { DurationFormatterOptions } from "@airmont/shared/ts/ui/time";

export interface PropertyReadProps {
  label: string;
  name?: string;
  value: PropertyValue;
  type?: PropertyValueType;
  multiline?: boolean;
  valueOptions?: ValueOptions;
  unit?: string;
  durationFormatterOptions?: DurationFormatterOptions;
  size?: TextField_size;
  fullWidth?: boolean;
  info?: ReactNode;
  sx?: SxProps;
}

export const PropertyRead: FC<PropertyReadProps> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  if (props.type === "link") {
    return <PropertyReadLink {...props} />;
  } else {
    if (Array.isArray(props.value)) {
      return (
        <PropertyWrapper
          label={props.label}
          name={props.name}
          size={props.size}
          fullWidth={props.fullWidth}
          multiline={props.multiline}
          sx={props.sx}
        >
          <Stack
            direction={"row"}
            gap={1}
            flexWrap={"wrap"}
            sx={{ pt: 0.5, ml: "14px" }}
          >
            {props.value.map((itemValue) => {
              const valueOption = props.valueOptions?.options.find((it) =>
                isValueOption(it) ? it.id === itemValue : it === itemValue
              );
              let label =
                typeof itemValue === "string"
                  ? itemValue
                  : itemValue.toString();
              if (valueOption != null && isValueOption(valueOption)) {
                label = valueOption.label;
              }
              return <Chip label={label} size={props.size} />;
            })}
          </Stack>
          {(props.unit != null || props.info) && (
            <Box
              sx={{
                marginLeft: "auto",
                maxHeight: "24px",
                marginRight: "14px",
              }}
            >
              {props.unit != null && (
                <Typography className={"unit"} component={"span"}>
                  {props.unit}
                </Typography>
              )}
              {props.info != null && (
                <Tooltip title={props.info}>
                  <IconButton size={"small"} sx={{ padding: 0 }}>
                    <HelpOutlineRoundedIcon
                      sx={{ color: theme.palette.text.disabled }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </PropertyWrapper>
      );
    } else {
      const displayValue = ValueUtils.resolveDisplayValue({
        value: props.value,
        type: props.type ?? resolvePropertyValueTypeFromValue(props.value),
        t: t,
        valueOptions: props.valueOptions,
        durationFormatterOptions: props.durationFormatterOptions,
      });

      return (
        <PropertyWrapper
          label={props.label}
          name={props.name}
          size={props.size}
          fullWidth={props.fullWidth}
          multiline={props.multiline}
          sx={props.sx}
        >
          <Typography
            component={"div"}
            noWrap={!props.multiline}
            sx={{ marginLeft: "14px" }}
          >
            {displayValue}
          </Typography>
          {(props.unit != null || props.info != null) && (
            <Box
              sx={{
                marginLeft: "auto",
                maxHeight: "24px",
                marginRight: "14px",
              }}
            >
              {props.unit != null && (
                <Typography className={"unit"} component={"span"}>
                  {props.unit}
                </Typography>
              )}
              {props.info != null && (
                <Tooltip title={props.info}>
                  <IconButton size={"small"} sx={{ padding: 0 }}>
                    <HelpOutlineRoundedIcon
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </PropertyWrapper>
      );
    }
  }
};
