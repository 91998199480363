import { BuildVersion } from "./BuildVersion";

export const humanReadableBuildVersion = (value: BuildVersion): string => {
  if (value === "local-debugging" || value === "local-dev") {
    return value;
  }
  const year = value.substring(0, 4);
  const month = value.substring(4, 6);
  const dayOfMonth = value.substring(6, 8);
  const buildOfDay = value.substring(9);

  return `${year}-${month}-${dayOfMonth}#${buildOfDay}`;
};
