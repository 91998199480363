import React, { FC } from "react";
import { PropertyEditBaseProps } from "./PropertyEditBaseProps";
import { usePropertyEditBase } from "./usePropertyEditBase";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ValueOptions } from "./ValueOptions";
import { RemoveButton } from "./RemoveButton";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface PropertyEditSelectProps extends PropertyEditBaseProps {
  value: number | string;
  valueOptions?: ValueOptions;
  disableFuture?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const PropertyEditSelect: FC<PropertyEditSelectProps> = (props) => {
  const { removable, handleRemove } = usePropertyEditBase(props);

  if (
    props.value != null &&
    typeof props.value !== "string" &&
    typeof props.value !== "number"
  ) {
    throw new Error("value must be of type string: " + props.value);
  }
  const value = props.value?.toString();

  const handleChange = (event: SelectChangeEvent) => {
    props.onChange(event.target.value, props.name);
  };

  const sx = useSxMerge(props.sx, {
    width: props.fullWidth ? "100%" : "25ch",
    ...(props.removable === true && {
      pr: "7px",
      ".MuiSelect-icon": {
        right: "33px",
      },
    }),
  });

  return (
    <FormControl variant={"outlined"} size={props.size}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        variant={"outlined"}
        label={props.label}
        value={value}
        sx={sx}
        inputRef={props.inputRef}
        onChange={handleChange}
        onBlur={props.onBlur}
        endAdornment={
          (props.info != null || props.removable === true) && (
            <InputAdornment position={"end"}>
              {removable && <RemoveButton onRemove={handleRemove} />}
            </InputAdornment>
          )
        }
      >
        {props.valueOptions?.options.map((option) => {
          if (typeof option === "string") {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          } else
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            );
        })}
      </Select>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};
