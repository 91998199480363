import { darken, useTheme } from "@mui/material";
import {
  useContainerColor,
  useOnContainerColor,
} from "@airmont/shared/ts/ui/mui";

export interface UseResolveColorsReturnType {
  onColor: string;
  onContainerColorWhenSelected: string;
  containerColorWhenSelected: string;
  containerColorOnHover: string;
  containerColorWhenSelectedOnHover: string;
}
export const useResolveItemColors = (): UseResolveColorsReturnType => {
  const theme = useTheme();
  const containerColorWhenSelected = useContainerColor("secondary");
  const onSecondaryContainerColor = useOnContainerColor("secondary");
  const containerColorOnHover = theme.palette.action.hover;
  const containerColorWhenSelectedOnHover = darken(
    containerColorWhenSelected,
    theme.palette.action.hoverOpacity
  );

  return {
    onColor: theme.palette.text.primary,
    onContainerColorWhenSelected: onSecondaryContainerColor,
    containerColorWhenSelected: containerColorWhenSelected,
    containerColorOnHover: containerColorOnHover,
    containerColorWhenSelectedOnHover: containerColorWhenSelectedOnHover,
  };
};
