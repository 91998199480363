import React, { FC, ReactNode, useRef } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
} from "@mui/material";
import { Action, resolveActionPreferences } from "./Action";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { isSelectableAction } from "./SelectableAction";
import { useClassName } from "./useResolveClassName";
import { ItemVariant } from "./ItemVariant";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { useResolveLayoutDirection } from "./useResolveLayoutDirection";
import { useResolveItemColors } from "./useResolveItemColors";
import { useResolveItemName } from "./useResolveItemName";

export interface ActionListItemProps {
  item: Action;
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  hideSelectionIcon?: boolean;
  hideLabel?: boolean;
  endAdornment?: ReactNode;
  sx?: SxProps;
}
export const ActionListItem: FC<ActionListItemProps> = (props) => {
  const { item, endAdornment } = props;
  const ref = useRef<HTMLLIElement>(null);
  const name = useResolveItemName(item, ref.current);
  const layoutDirection = useResolveLayoutDirection(props);
  const hideLabel = props.hideLabel ?? false;
  const preferences = resolveActionPreferences(item.preferences, props.layout);
  const hideSelectionIcon =
    props.hideSelectionIcon ?? preferences?.hideSelectionIcon ?? false;
  const selected = isSelectableAction(item) ? item.selected : undefined;
  const selectable = selected !== undefined;

  const classNameRoot = useClassName(props, [
    "Item",
    "Action",
    "ActionListItem",
  ]);

  const classNameButton = useClassName(props, ["ActionListItem-button"]);

  const {
    onColor,
    onContainerColorWhenSelected,
    containerColorWhenSelected,
    containerColorOnHover,
    containerColorWhenSelectedOnHover,
  } = useResolveItemColors();

  const handleClick = () => {
    item.onExecute(item);
  };

  const sx = useSxMerge(props.sx, {
    "&.variant-rounded": {
      borderRadius: "50px",
    },
    "&.direction-column": {
      "&.layout-medium": {
        borderRadius: 3,
      },
    },
  });

  return (
    <ListItem
      ref={ref}
      onClick={handleClick}
      className={classNameRoot}
      disablePadding
      disableGutters
      sx={sx}
    >
      <ListItemButton
        className={classNameButton}
        sx={{
          borderRadius: "inherit",
          ">.MuiListItemText-root": {
            ">.MuiTypography-root": {
              fontSize: "inherit",
              textAlign: "inherit",
            },
          },
          "&.layout-medium": {
            fontSize: "0.75rem",
            "&.direction-column": {
              ".MuiListItemIcon-root": {
                height: "32px",
                width: "56px",
              },
            },
          },
          "&.layout-compact": {
            fontSize: "0.75rem",
            "&.direction-column": {
              ".MuiListItemIcon-root": {
                height: "32px",
                width: "56px",
              },
            },
          },
          "&.direction-row": {
            textAlign: "left",
            ".MuiListItemText-root": {
              minHeight: "20px",
            },
            "&.selected": {
              color: onContainerColorWhenSelected,
              backgroundColor: containerColorWhenSelected,
            },
            "&:hover": {
              backgroundColor: selected
                ? containerColorWhenSelectedOnHover
                : containerColorOnHover,
            },
          },
          "&.direction-column": {
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: "4px",
            ".MuiListItemIcon-root": {
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "15px",
              color: onColor,
            },
            "&.selected": {
              backgroundColor: "unset",
              ".MuiListItemIcon-root": {
                backgroundColor: containerColorWhenSelected,
                color: onContainerColorWhenSelected,
              },
            },
            "&:hover": {
              backgroundColor: "unset",
              ".MuiListItemIcon-root": {
                backgroundColor: selected
                  ? containerColorWhenSelectedOnHover
                  : containerColorOnHover,
              },
            },
          },
        }}
      >
        {selectable && !hideSelectionIcon && selected && (
          <ListItemIcon className={"CheckIcon"}>
            <CheckRoundedIcon />
          </ListItemIcon>
        )}
        {item.icon != null && <ListItemIcon>{item.icon}</ListItemIcon>}
        {!hideLabel && (
          <ListItemText
            inset={selectable && !hideSelectionIcon && !selected}
            primary={name}
          />
        )}
        {endAdornment != null && layoutDirection === "row" && endAdornment}
      </ListItemButton>
    </ListItem>
  );
};
