import { FC } from "react";
import { AuditInfoDto } from "./AuditInfoDto";
import {
  PropertiesCard,
} from "@airmont/shared/ts/ui/properties-card";
import {Property} from "shared-ts-property";

export interface AuditPaneProps {
  audit: AuditInfoDto;
}
export const AuditPane: FC<AuditPaneProps> = (props) => {
  return (
    <PropertiesCard>
      <Property
        mode={"read"}
        label={"Opprettet"}
        value={props.audit.created}
        type={"dateTime"}
      />
      <Property
        mode={"read"}
        label={"Opprettet av"}
        value={props.audit.createdBy}
        type={"string"}
      />
      <Property
        mode={"read"}
        label={"Endret"}
        value={props.audit.modified}
        type={"dateTime"}
      />
      <Property
        mode={"read"}
        label={"Endret av"}
        value={props.audit.modifiedBy}
        type={"string"}
      />
    </PropertiesCard>
  );
};
