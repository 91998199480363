import { Property, PropertyMode } from "shared-ts-property";
import React, { FC } from "react";
import { YesNoUnknownStringEnum } from "../../YesNoUnknownEnum";
import { useTranslation } from "react-i18next";

export interface FireplaceCatalyticPropertyProps {
  propertyMode: PropertyMode;
  value: YesNoUnknownStringEnum | null | undefined;
  onChange?: (
    name: string,
    value: YesNoUnknownStringEnum | null | undefined
  ) => void;
}
export const FireplaceCatalyticProperty: FC<FireplaceCatalyticPropertyProps> = (
  props
) => {
  const { propertyMode, value } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  return (
    <Property
      name="fireplaceCatalytic"
      label={tFireflySharedDomain("Clean-burning Fireplace")}
      mode={propertyMode}
      type={"string"}
      value={value}
      onChange={(value) =>
        props.onChange?.("fireplaceCatalytic", value as typeof props.value)
      }
      valueOptions={{
        multiple: false,
        restrictToOptions: true,
        options: [
          {
            id: YesNoUnknownStringEnum.Yes,
            label: tFireflySharedDomain(
              `YesNoUnknown.${YesNoUnknownStringEnum.Yes}`
            ),
          },
          {
            id: YesNoUnknownStringEnum.No,
            label: tFireflySharedDomain(
              `YesNoUnknown.${YesNoUnknownStringEnum.No}`
            ),
          },
          {
            id: YesNoUnknownStringEnum.Unknown,
            label: tFireflySharedDomain(
              `YesNoUnknown.${YesNoUnknownStringEnum.Unknown}`
            ),
          },
        ],
      }}
    />
  );
};
