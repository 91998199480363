import { ReactElement } from "react";
import { Item } from "./Item";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { Layout } from "./Layout";

export interface ActionPreferenceProps {
  hideSelectionIcon?: boolean;
}

export interface ActionPreferences extends ActionPreferenceProps {
  compact?: ActionPreferenceProps;
  medium?: ActionPreferenceProps;
  large?: ActionPreferenceProps;
}

export interface Action extends Item {
  type: "Action";
  name: string;
  nameBreakable?: string;
  onExecute: (item: Action) => void;
  reference?: string;
  icon?: ReactElement;
  shortcut?: string;
  disabled?: boolean;
  preferences?: ActionPreferences;
}

export function isAction(obj: object): obj is Action {
  const check =
    typeof obj === "object" && "type" in obj && obj.type === "Action";
  if (check) {
    if (!("name" in obj && typeof obj.name === "string")) {
      throw new IllegalStateError("A Action requires name property");
    }
    if (!("onExecute" in obj && typeof obj.onExecute === "function")) {
      throw new IllegalStateError("A Action requires onExecute property");
    }
  }
  return check;
}

export const resolveActionPreferences = (
  actionPreferences: ActionPreferences | undefined,
  layout: Layout
): ActionPreferences | undefined => {
  if (actionPreferences === undefined) {
    return undefined;
  }
  const preferenceByLayout = actionPreferences[layout];
  if (preferenceByLayout != null) {
    return preferenceByLayout;
  }
  return actionPreferences;
};
