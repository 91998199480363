import React, { FC, useRef } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
} from "@mui/material";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import { RouteItem } from "./RouteItem";
import { isSelectableItem } from "./SelectableItem";
import { useClassName } from "./useResolveClassName";
import { ItemVariant } from "./ItemVariant";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { useResolveItemColors } from "./useResolveItemColors";
import { useResolveItemName } from "./useResolveItemName";

export interface RouteListItemProps {
  item: RouteItem;
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  hideSelection?: boolean;
  hideLabel?: boolean;
  onSelected?: (item: RouteItem) => void;
  sx?: SxProps;
}

export const RouteListItem: FC<RouteListItemProps> = (props) => {
  const { item, onSelected } = props;
  const ref = useRef<HTMLLIElement>(null);
  const name = useResolveItemName(item, ref.current);
  const hideLabel = props.hideLabel ?? false;
  const selected = isSelectableItem(item) ? item.selected : undefined;
  const classNameRoot = useClassName(props, ["Item", "RouteListItem"]);
  const classNameButton = useClassName(props, ["Item", "RouteListItem-button"]);

  const handleClick = () => {
    onSelected?.(item);
  };

  const sx = useSxMerge(props.sx, {
    "&.variant-rounded": {
      borderRadius: "50px",
    },
    "&.direction-column": {
      "&.layout-medium": {
        borderRadius: 3,
      },
    },
  });

  const {
    onColor,
    onContainerColorWhenSelected,
    containerColorWhenSelected,
    containerColorOnHover,
    containerColorWhenSelectedOnHover,
  } = useResolveItemColors();

  return (
    <ListItem
      ref={ref}
      className={classNameRoot}
      disablePadding
      disableGutters
      sx={sx}
    >
      <ListItemButton
        className={classNameButton}
        onClick={handleClick}
        sx={{
          borderRadius: "inherit",
          ">.MuiListItemText-root": {
            ">.MuiTypography-root": {
              fontSize: "inherit",
              textAlign: "inherit",
            },
          },
          "&.layout-medium": {
            fontSize: "0.75rem",
            "&.direction-column": {
              ".MuiListItemIcon-root": {
                height: "32px",
                width: "56px",
              },
            },
          },
          "&.layout-compact": {
            fontSize: "0.75rem",
            "&.direction-column": {
              ".MuiListItemIcon-root": {
                height: "32px",
                width: "56px",
              },
            },
          },
          "&.direction-row": {
            textAlign: "left",
            "&.selected": {
              color: onContainerColorWhenSelected,
              backgroundColor: containerColorWhenSelected,
            },
            "&:hover": {
              backgroundColor: selected
                ? containerColorWhenSelectedOnHover
                : containerColorOnHover,
            },
          },
          "&.direction-column": {
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: "4px",
            ".MuiListItemIcon-root": {
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "15px",
              color: onColor,
            },
            "&.selected": {
              backgroundColor: "unset",
              ".MuiListItemIcon-root": {
                backgroundColor: containerColorWhenSelected,
                color: onContainerColorWhenSelected,
              },
            },
            "&:hover": {
              backgroundColor: "unset",
              ".MuiListItemIcon-root": {
                backgroundColor: selected
                  ? containerColorWhenSelectedOnHover
                  : containerColorOnHover,
              },
            },
          },
        }}
      >
        {item.icon != null && <ListItemIcon>{item.icon}</ListItemIcon>}
        {!hideLabel && <ListItemText primary={name} />}
      </ListItemButton>
    </ListItem>
  );
};
